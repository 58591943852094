import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Accessibility from './components/Accessibility';
import {BrowserRouter, Routes , Route} from 'react-router-dom'
import Home from './components/pages/Home';
import ContactUs from './components/pages/ContactUs';
import Login from './components/pages/Login';
import Manager from './components/pages/Manager';
import ProjectDetails from './components/pages/ProjectDetails'
import User from './components/pages/User';
import Projects from './components/pages/Projects'
import AboutUs from './components/pages/AboutUs'
import Services from './components/pages/Services'
import ServiceTracking from './components/pages/ServiceTracking'
import Process from './components/pages/Process'
import Main from './components/pages/Main';
import Treats from './components/pages/AllTreatments'
import AllCalls from './components/pages/AllCalls'

import CallsFault from './components/pages/CallsFaults';
import Videos from './components/pages/Videos';
import Extension from './components/Extension';
import LanguageSelector from './components/LanguageSelector';

function App() {
  return (
   <>
   <BrowserRouter>
   
      <Navbar/>
      <Accessibility/>
      {/* <LanguageSelector/> */}
      {/* <Extension/> */}
      <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='/צור קשר' element={<ContactUs/>}/>
        <Route path='/התחברות' element={<Login/>}/>
        <Route path='/manager' element={<Manager/>}/>
        <Route path='/עדכוןפרטים' element={<ProjectDetails/>}/>
        <Route path='/כניסת משתמש' element={<User/>}/>
        <Route path='/פרויקטים' element={<Projects/>}/>
        <Route path='/סרטונים/פרויקטים' element={<Videos/>}/>
        <Route path='/אודות' element={<AboutUs/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/מעקב שירות' element={<ServiceTracking/>}/>
        <Route path='/התהליך' element={<Process/>}/>
        <Route path='/קריאה ותקלה/:name' element={<CallsFault/>}/>
        <Route path='/טיפולים' element={<Treats/>}/>
        <Route path='/שירות' element={<AllCalls/>}/>
        
      </Routes>
      <Footer/>
      
      </BrowserRouter> 
    </>
  );
}

export default App;
