import i18n from "i18next"
import {initReactI18next} from "react-i18next"

import translationsInEng from '../locales/en/translation.json'
import translationsInHeb from '../locales/heb/translation.json'

//the translations
const resources = {
    en: {
        translation: translationsInEng
    },
    heb: {
        translation: translationsInHeb
    }
}

i18n.use(initReactI18next).init({
    resources,
    lng: "heb",
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    },
    ns: "translation",
    defaultNS: "translation"
})

export default i18n;