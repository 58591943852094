import React from 'react'
import './Extension.css'
function Extension() {
  return (
    <div className='extension'>
        <img src='/maofNew3.png' alt='exten-img'/>
        <h4>מחזקים את החיילים וכוחות הביטחון ומתפללים לשובם של החטופים והחטופות</h4>
        <h5>"הקדוש ברוך הוא ישמרם ויצילם מכל צרה וצוקה ומכל נגע ומחלה וישלח ברכה והצלחה בכל מעשי ידיהם"</h5>
    </div>
  )
}

export default Extension