export const gallery = [
    {
        title: "בית פרטי-ראשון לציון",
        description: "הוספת מעלון כולל פיר זכוכית בבית פרטי",
        photos: ['/projects/rshlaz/rshlz.jpeg',
        '/projects/rshlaz/rshlz1.jpeg',
         '/projects/rshlaz/rshlz2.jpeg',
        '/projects/rshlaz/rshlz3.jpeg',
       '/projects/rshlaz/rshlz4.jpeg']
    }, 
    {
        title:"בית משותף - שד' בן ציון, תל אביב",
        description: "הוספת מעלית בבניין קיים, בתוך חדר המדרגות. חדר המדרגות במסגרת אגף השימור.",
        photos: ['/projects/bentzion/bentzion1.jpeg','/projects/bentzion/bentzion2.jpeg', '/projects/bentzion/bentzion3.jpeg']
    },
    {
        title:"בית פרטי - ראשון לציון",
        description:"הוספת מעלית פנורמית בבית פרטי",
        photos: ['/projects/mendel/mendel1.jpeg','/projects/mendel/mendel2.jpeg', '/projects/mendel/mendel3.jpeg','/projects/mendel/mendel4.jpeg', '/projects/mendel/mendel5.jpeg']
    },
    {
        title:"בית כנסת - פתח תקווה",
        description:"מעלית חיצונית מונגשת כולל פיר וגשר",
        photos: ['/projects/petahTiqwa/petahTiqwa1.jpeg', '/projects/petahTiqwa/petahTiqwa2.jpeg','/projects/petahTiqwa/petahTiqwa3.jpeg','/projects/petahTiqwa/petahTiqwa4.jpeg']
    },
    {
        title: "בית קיים - יהודה הנשיא ר'ג",
        description: "הרכבה והעברת פיר מעלית קומפלט מהמפעל לאתר",
        photos: [
            '/projects/yehuda/yehuda1.jpeg',
            '/projects/yehuda/yehuda2.jpeg',
            '/projects/yehuda/yehuda3.jpeg',
            '/projects/yehuda/yehuda4.jpeg',
            '/projects/yehuda/yehuda5.jpeg',
            '/projects/yehuda/yehuda6.jpeg',
            '/projects/yehuda/yehuda7.jpeg',
            '/projects/yehuda/yehuda8.jpeg',
            '/projects/yehuda/yehuda9.jpeg'
        ]
    },{
        title: "בית קיים - ברקאי ר'ג",
        description:"הוספת מעלית בבניין קיים, שינוי מהלך מדרגות והגדלת הלובי",
        photos: [
            'projects/bark/1.jpeg',
            'projects/bark/2.jpeg',
            'projects/bark/3.jpeg',
            'projects/bark/5.jpeg',
            'projects/bark/8.jpeg',
            'projects/bark/9.jpeg',
            'projects/bark/10.jpeg',
            'projects/bark/11.jpeg',
            'projects/bark/12.jpg'
        ]
    },{
            title: "בניין קיים - ענתות ת'א",
            description:"הוספת מעלון בבניין קיים עם פיר זכוכית חלבית שמוסיף יופי לחדר מדרגות",
            photos: [
                'projects/anatot/a1.jpeg',
                'projects/anatot/a2.jpeg',
                'projects/anatot/a3.jpeg',
                'projects/anatot/a4.jpeg',
                'projects/anatot/a5.jpeg',
            ]
        }

 
]