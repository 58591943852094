import React, { useState } from 'react';
import './CustomerCard.css';
import {useNavigate} from 'react-router-dom'
import Popup from './Popup';


function CustomerCard({title, num, func}){
    const navigate = useNavigate()
    const [popupDelete, setPopupDelete] = useState(false)
    const pop=()=>{
        setPopupDelete(!popupDelete)
    }
    const delet=()=>{
        func(num, title)
        pop()
        alert("פרויקט נמחק בהצלחה")
    }
   //onClick={()=>{func( num, title)}}
    return(
        <>
        
        
                      <div className=' text-center  m-3 position-sticky
    d-flex justify-content-end p-2 pt-2 '>
            <button className='btn btn-danger m-0' onClick={pop} >
                מחק<i className="fas fa-trash"></i>
                </button></div>
            <h5 className=''>{title}</h5>
            <p className=''>{num}</p>
            <div className='p-2'>
            <button className='btn btn-info m-0' onClick={()=>{navigate('/עדכוןפרטים',{state:num})}}>
                עדכן<i className="fas fa-pen"></i>
                </button></div>
        
        <div>
            {popupDelete && <Popup handleClose={pop}
           content={<div>
            <h3>אתה בטוח רוצה למחוק את הפרויקט הזה ?</h3>
            <button onClick={()=>{delet()}}>כן</button>
           </div>
           } />}
        </div>
        
        </>
    )
}
export default CustomerCard;